<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <g id="Icon_Tabbar_Lock">
      <path
        id="Top"
        d="m9.55,5.34v-1.73c0-1.63-1.32-2.95-2.95-2.95h0c-1.63,0-2.95,1.32-2.95,2.95v1.73"
        style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
      />
      <path
        id="Bottom"
        d="m10.66,14.17H2.25c-.52,0-.93-.42-.93-.93v-6.54c0-.52.42-.93.93-.93h8.41c.52,0,.93.42.93.93v6.54c0,.52-.42.94-.93.94h0Z"
        style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
      />
    </g>
    <line
      x1="6.45"
      y1="8.45"
      x2="6.45"
      y2="11.27"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
